<template>
  <div>
    <div class="container ceo-profile">
      <div class="ceo-content-11">{{ ceo.name }}</div>
      <div class="d-flex ceo-container">
        <div class="img-ceo col-12 col-lg-4">
          <img
            alt="aboutCeo"
            src="../../public/images/aboutus/about-ceo.png"
            width="90%"
          />
          <span>{{ ceo.title_image_1 }}</span>
        </div>
        <div class="ceo-content col-12 col-lg-8">
          <div class="ceo-content-1">{{ ceo.ceo_content_1 }}</div>
          <div class="ceo-content-2">{{ ceo.ceo_content_2 }}</div>
          <div class="ceo-content-3">{{ ceo.ceo_content_3 }}</div>
          <div class="ceo-content-3">{{ ceo.ceo_content_4 }}</div>
        </div>
      </div>
      <div class="d-flex ceo-container">
        <div class="ceo-content col-12 col-lg-8">
          <div class="ceo-content-3">{{ ceo.ceo_content_5 }}</div>
          <div class="ceo-content-3">{{ ceo.ceo_content_6 }}</div>
        </div>
        <div class="img-ceo col-12 col-lg-4">
          <img
            alt="aboutCeo"
            src="../../public/images/aboutus/ceo-2.jpg"
            width="60%"
          />
          <span>{{ ceo.title_image_2 }}</span>
        </div>
      </div>
      <div class="ceo-achievements">
        <div class="ceo-content-11">{{ ceo.title_content_1 }}</div>
        <table class="table-achievements">
          <tr v-for="(item, idx) in ceo.array_achievements" :key="idx">
            <td style="text-align: center; width: 40px">{{ item.id }}</td>
            <td>{{ item.description }}</td>
            <td class="col-year">{{ item.year }}</td>
          </tr>
        </table>
      </div>
      <div class="ceo-achievements">
        <div class="ceo-content-11">{{ ceo.title_content_2 }}</div>
        <div class="d-flex ceo-milestones">
          <div
            v-for="(item, idx) in ceo.milestones_content"
            :key="idx"
            class="d-flex flex-column milestones_content"
          >
            <img
              alt="milestones"
              v-bind:src="'/images/milestones' + (idx + 1) + '.png'"
            />
            <div style="max-width: 205px">
              <span class="font-weight-bold d-block">{{
                item.split('\n')[0]
              }}</span>
              {{ item.split('\n')[1] }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapState } from 'vuex';
const Footer = () => import('../views/FooterNew.vue');

export default {
  name: 'Ceo',
  data() {
    return {
      header: this.$t('header'),
      about_us_head: this.$t('about_us.about_us_head'),
      about_us_foot: this.$t('about_us.about_us_foot'),
      our_video: this.$t('about_us.header_video'),
      content_members: this.$t('about_us.members'),
      ceo: this.$t('ceo'),
      id: null,
    };
  },
  props: {
    data: Array,
  },
  created() {
    this.id = this.$route.query.id || null;
  },
  watch: {
    lang() {
      this.header = this.$t('header');
      this.about_us_head = this.$t('about_us.about_us_head');
      this.about_us_foot = this.$t('about_us.about_us_foot');
      this.our_video = this.$t('about_us.header_video');
      this.content_members = this.$t('about_us.members');
      this.ceo = this.$t('ceo');
    },
  },
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang,
    }),
  },
  components: {
    Footer,
  },
};
</script>

<style lang="scss" scoped>
h3.heading {
  font-size: 40px;
  color: #4081ec;
}
p.title {
  color: #f27323;
  font-weight: bold;
  font-size: 25px;
}
h4 {
  font-size: 20px;
}

.ceo-profile {
  color: var(--grey-text);
  padding: 150px 0 100px 0;
  .ceo-achievements {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    margin-bottom: 30px;
    table {
      margin-top: 20px;
      border-collapse: collapse;
      width: 100%;
    }
    td,
    th {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }
    .col-year {
      width: 150px;
    }
    img {
      width: 90%;
    }
    .ceo-milestones {
      gap: 20px;
    }
    .milestones_content {
      flex: 1;
      align-items: center;
      text-align: center;
      gap: 20px;
      font-size: 16px;
    }
  }
  .img-ceo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    padding: 30px 0;
    span {
      font-weight: 600;
      font-style: italic;
      color: var(--grey-text);
      text-align: center;
      width: 80%;
    }
    img {
      border-radius: 14px;
    }
  }
  .ceo-content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    .ceo-content-1 {
      margin: 10px;
      padding: 24px 35px;
      background: #f4f7fd;
      border-radius: 14px;
    }
    .ceo-content-2 {
      font-weight: 600;
      color: var(--grey-text);
    }
    .ceo-content-2,
    .ceo-content-3 {
      padding: 0 20px;
    }
  }
  .ceo-content-11 {
    color: var(--blue-text);
    //text-transform: uppercase;
    margin-bottom: 50px;
    text-align: center;
    font-weight: 700;
    font-size: 40px;
  }

  @media (max-width: 1024px) {
    .ceo-profile {
      margin-top: 100px;
    }
  }
  @media (max-width: 820px) {
    .container {
      width: 90vw;
    }
    .ceo-profile {
      padding: 50px 0;
      .img-ceo {
        padding: 10px 0;
      }
      .ceo-achievements {
        .col-year {
          width: 80px;
        }
      }
    }
    .ceo-container {
      flex-direction: column;
      justify-content: center;
    }
    .ceo-profile .ceo-content-11 {
      font-size: 32px;
      margin-bottom: 30px !important;
    }
  }
  @media (max-width: 576px) {
    .ceo-profile {
      margin-top: 100px;
    }
    h3.heading {
      font-size: 30px;
      color: #4081ec;
    }
    p.title {
      color: #f27323;
      font-weight: bold;
      font-size: 20px;
    }
    .owner {
      text-align: center;
      img {
        width: 60%;
      }
    }
    .ceo-achievements .ceo-milestones {
      flex-direction: column;
      gap: 30px;
      img {
        max-width: 80%;
      }
    }
    .card {
      .avatar-profile {
        top: -45px;
        img {
          height: 90px;
          width: 90px;
        }
      }
      .content {
        padding: 50px 20px 0px 20px;
      }
    }
  }
}
.card {
  border-top: 3px solid black;
  background-color: #f3fff1 !important;
  position: relative;
  min-height: 200px;
  margin: 20px 0px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  .avatar-profile {
    position: absolute;
    left: 50%;
    top: -55px;
    transform: translate(-50%, 0%);
    img {
      height: 110px;
      width: 110px;
      object-fit: cover;
      border-radius: 50%;
      border: 2px solid white;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
  }
  .content {
    text-align: center;
    padding: 70px 20px 0px 20px;
  }
  .content-detail {
    padding: 0px 20px 0px 20px;
  }
}
</style>
